@import "~@fluentui/react/dist/sass/References";

.footer-container {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid $ms-color-gray30;
    justify-content: flex-end;
    background-color: $ms-color-white;
    align-items: center;

    .privacy-link {
        color:$ms-color-black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    .copyright-year {
        margin: 0 20px;
        line-height: 0;
    }
}