.main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .page-container {
        display: flex;
        flex-grow: 1;
    }
}
