.page-control {
    display: flex;
    gap: 5px;
    align-items: center;

    .page-number {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
