.inline-label-menu-item {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inline-label-menu-input {
    padding: 6px;
}

.inline-label-menu-list {
    overflow: auto;
}
