@import "~@fluentui/react/dist/sass/References";

.label-canvas {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .label-canvas-command-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 0 24px;
        border-bottom: 1px solid $ms-color-gray30;
    }

    .label-canvas-control-bar {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        height: 44px;
        border-top: 1px solid $ms-color-gray30;
        padding: 0 24px;

        .label-canvas-page-control {
            grid-column-start: 2;
        }

        .label-canvas-tool-bar {
            display: flex;
            margin-left: auto;
        }
    }

    .label-canvas-image-map {
        display: flex;
        flex-grow: 1;
        position: relative;
        overflow: hidden;

        .label-canvas-overlay {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.9);
            height: 100%;
            width: 100%;
        }
    }
}
